import React from "react";
import "./FormLayout.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const FormLayout = ({ path, title, description, children }) => {
  return (
    <div className="FormLayout">
      <div className="FormLayout__Wrapper">
        <div className="FormLayout__Header">
          <div className="FormLayout__HeaderTitle">
            <a href={path}>
              <ArrowBackIcon className="Icon" />
            </a>
            <div>
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
          </div>
        </div>
        <div className="FormLayout__Content">{children}</div>
      </div>
    </div>
  );
};

export default FormLayout;
