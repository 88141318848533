import React, { useState, useEffect } from "react";
import FormLayout from "../../layouts/FormLayout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { alertService } from "../../services/AlertService";

import {
  ADD_SERVICE,
  EDIT_SERVICE,
  GET_SERVICE,
} from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";

import * as Yup from "yup";

const ServiceForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const request = FetchWrapper();
  const { id } = useParams();
  const isAddMode = !id;

  const initialValues = {
    title: "",
    description: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Renseigner le nom du service"),
    description: Yup.string().required("Renseigner le description"),
  });

  const handleSubmit = (values, { setStatus, setSubmitting, resetForm }) => {
    setStatus();
    if (isAddMode) {
      createService(values, setSubmitting);
    } else {
      updateService(id, values, setSubmitting);
    }
  };

  const createService = async (values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(ADD_SERVICE, values);
      if (response) {
        alertService.success("Nouveau service creer", {
          keepAfterRouteChange: true,
        });
        const { from } = location.state || {
          from: { pathname: "/service" },
        };
        navigate(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  const updateService = async (id, values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(EDIT_SERVICE + id, values);
      if (response) {
        alertService.success("Mise a jour effectuer", {
          keepAfterRouteChange: true,
        });
        const { from } = location.state || {
          from: { pathname: "/service" },
        };
        navigate(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  return (
    <FormLayout
      title={isAddMode ? "Ajouter un service" : "Modifier le service"}
      path={isAddMode ? "." : ".."}
      description="veuillez renseigner les informations ci-dessous"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {function Render({ isSubmitting, setFieldValue }) {
          const [service, setService] = useState({});

          useEffect(async () => {
            if (!isAddMode) {
              const response = await request.get(GET_SERVICE + id);
              setService(response);
              if (response) {
                const fields = ["title", "description"];
                fields.forEach((field) => {
                  if (field === "date") {
                    console.log(response[field]);

                    let date = new Date(response[field]);

                    let day = date.getDate();
                    let month = date.getMonth() + 1;
                    let year = date.getFullYear();
                    setFieldValue(field, year + "-" + month + "-" + day, false);
                  } else {
                    setFieldValue(field, response[field], false);
                  }
                });
              }
            }
          }, []);

          return (
            <Form>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="title">
                  Nom du service
                </label>
                <div className="col-sm-10">
                  <Field className="form-control" name="title" type="text" />
                  <ErrorMessage name="title" />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  className="col-sm-2 col-form-label"
                  htmlFor="description"
                >
                  Description
                </label>
                <div className="col-sm-10">
                  <Field
                    className="form-control"
                    name="description"
                    as="textarea"
                    type="text"
                  />
                  <ErrorMessage
                    className="invalid-feedback"
                    name="description"
                  />
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary mr-3"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Enregistrer
                </button>
                <Link to={isAddMode ? "." : ".."} className="btn btn-light">
                  Annuler
                </Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default ServiceForm;
