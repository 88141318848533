import React, { useContext } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";

export const FetchWrapper = () => {
  const { user } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: user ? `Bearer ${user.token}` : "",
    },
  };

  const postRequest = async (url, payload = {}, options = {}) => {
    const data = await axios
      .post(url, payload, { ...config, ...options })
      .then((resp) => resp.data)
      .catch((err) => ({ error: err.response.data }));
    return data;
  };

  const putRequest = async (url, payload = {}, options = {}) => {
    const data = await axios
      .put(url, payload, { ...config, ...options })
      .then((resp) => resp.data)
      .catch((err) => ({ error: err.response.data }));
    return data;
  };

  const getRequest = async (url, options = {}) => {
    const data = await axios
      .get(url, { ...config, ...options })
      .then((resp) => resp.data)
      .catch((err) => ({ error: err.response.data }));
    return data;
  };

  const removeRequest = async (url, payload = {}, options = {}) => {
    const data = await axios
      .post(url, payload, { ...config, ...options })
      .then((resp) => resp.data)
      .catch((err) => ({ error: err.response.data }));
    return data;
  };

  return {
    post: postRequest,
    put: putRequest,
    remove: removeRequest,
    get: getRequest,
  };
};

export const postRequest = async (url, payload = {}) => {
  const data = await axios
    .post(url, payload)
    .then((resp) => resp.data)
    .catch((err) => ({ error: err.response.data }));
  return data;
};

export const putRequest = async (url, payload = {}) => {
  const data = await axios
    .put(url, payload)
    .then((resp) => resp.data)
    .catch((err) => ({ error: err.response.data }));
  return data;
};

export const getRequest = async (url) => {
  const data = await axios
    .get(url)
    .then((resp) => resp.data)
    .catch((err) => ({ error: err.response.data }));
  return data;
};

export const deleteRequest = async (url) => {
  const data = await axios
    .delete(url)
    .then((resp) => resp.data)
    .catch((err) => ({ error: err.response.data }));
  return data;
};
