import React, { useState, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";

import { Link } from "react-router-dom";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import { GET_SERVICE_LIST, REMOVE_SERVICE } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";

const ServiceList = () => {
  const actions = [{ url: "/service/new", title: "Nouveau service" }];

  const request = FetchWrapper();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(async () => {
    const response = await request.get(GET_SERVICE_LIST);
    setRows(response);
  }, []);

  const handleDelete = async (id) => {
    setRows(
      rows.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    const response = await request.remove(REMOVE_SERVICE + id, { id });
    if (response) {
      setRows((rows) => rows.filter((x) => x.id !== id));
    }
  };

  return (
    <PageLayout
      title="Liste des services"
      description="list des services de sante"
      actions={actions}
    >
      <div>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Nom</th>
              <th style={{ width: "30%" }}>Description</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((row) => (
                <tr key={row.id}>
                  <td>{row.title}</td>
                  <td>{row.description}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <div className="ButtonIconContainer">
                      <Link className="ButtonIcon" to={`/service/${row.id}`}>
                        <ContentPasteSearchOutlinedIcon />
                      </Link>
                      <Link
                        className="ButtonIcon"
                        to={`/service/edit/${row.id}`}
                      >
                        <ModeEditOutlineOutlinedIcon />
                      </Link>
                      <button
                        onClick={() => handleDelete(row.id)}
                        className="ButtonIcon"
                        disabled={row.isDeleting}
                      >
                        {row.isDeleting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <DeleteOutlineOutlinedIcon />
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            {!rows && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="spinner-border spinner-border-lg align-center"></div>
                </td>
              </tr>
            )}
            {rows && !rows.length && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="p-2">Aucun service</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </PageLayout>
  );
};

export default ServiceList;
