import React, { useState, useEffect } from "react";
import FormLayout from "../../layouts/FormLayout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { MyTextInput, MyCheckbox } from "../Inputs";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { alertService } from "../../services/AlertService";
import { history } from "../../helpers/history";

import { countryList } from "../../Countries";

import {
  ADD_PATIENT,
  EDIT_PATIENT,
  GET_PATIENT,
  GET_COMPANY_LIST,
  GET_SERVICE_LIST,
} from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";

import * as Yup from "yup";

const CompanyForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const request = FetchWrapper();
  const { id } = useParams();
  const isAddMode = !id;

  const [countries, setCountries] = useState(countryList);

  const initialValues = {
    role: "patient",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    birthday: "",
    country: "",
    gender: "",
    work: "",
    maritalStatus: "",
    password: "",
    medicalCover: false,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Champs requis"),
    lastName: Yup.string().required("Champs requis"),
    email: Yup.string()
      .email("Cette adresse email est invalide")
      .required("Champs requis"),
    telephone: Yup.string().required("Champs requis"),
    birthday: Yup.string().required("Champs requis"),
    country: Yup.string().required("Champs requis"),
    gender: Yup.string().required("Champs requis"),
  });

  const handleSubmit = (values, { setStatus, setSubmitting, resetForm }) => {
    setStatus();
    if (isAddMode) {
      createPatient(values, setSubmitting);
    } else {
      updatePatient(id, values, setSubmitting);
    }
  };

  const createPatient = async (values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(ADD_PATIENT, values);
      if (response) {
        alertService.success("Nouveau patient creer", {
          keepAfterRouteChange: true,
        });
        const { from } = location.state || {
          from: { pathname: "." },
        };
        navigate(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  const updatePatient = async (id, values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(EDIT_PATIENT + id, values);
      if (response) {
        alertService.success("Mise a jour effectuer", {
          keepAfterRouteChange: true,
        });
        const { from } = history.location.state || {
          from: { pathname: ".." },
        };
        navigate(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  return (
    <FormLayout
      title={
        isAddMode ? "Ajouter un patient" : "Modifier les infos. du patient"
      }
      path={isAddMode ? "." : ".."}
      description="veuillez renseigner les informations ci-dessous"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {function Render({ isSubmitting, setFieldValue }) {
          const [patient, setPatient] = useState({});

          useEffect(async () => {
            if (!isAddMode) {
              const response = await request.get(GET_PATIENT + id);
              console.log(response);
              setPatient(response);
              if (response) {
                const fields = [
                  "firstName",
                  "lastName",
                  "gender",
                  "email",
                  "telephone",
                  "birthday",
                  "country",
                ];
                fields.forEach((field) => {
                  if (field === "birthday") {
                    let date = new Date(response[field]);
                    let day = date.getDate();
                    let month = date.getMonth() + 1;
                    let year = date.getFullYear();
                    setFieldValue(field, year + "-" + month + "-" + day, false);
                    console.log();
                  } else {
                    setFieldValue(field, response[field], false);
                  }
                });
              }
            }
          }, []);

          return (
            <Form>
              <fieldset>
                <legend>Informations</legend>
                <div>
                  <div className="row mb-3">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="firstName"
                    >
                      Nom
                    </label>
                    <div className="col-sm-5">
                      <Field
                        className="form-control"
                        name="firstName"
                        placeholder="Nom de famille"
                        type="text"
                      />
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="lastName"
                    >
                      Prenoms
                    </label>
                    <div className="col-sm-5">
                      <Field
                        className="form-control"
                        name="lastName"
                        placeholder="Prenoms"
                        type="text"
                      />
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label" htmlFor="gender">
                      Sexe
                    </label>
                    <div className="col-sm-5">
                      <Field
                        as="select"
                        className="form-control"
                        name="gender"
                        id="gender"
                      >
                        <option value="">Choisir le sexe</option>
                        <option value="M">Masculin</option>
                        <option value="F">Feminin</option>
                      </Field>
                      <ErrorMessage name="company" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="birthday"
                    >
                      Date de naissance
                    </label>
                    <div className="col-sm-5">
                      <Field
                        className="form-control"
                        name="birthday"
                        type="date"
                      />
                      <ErrorMessage name="birthday" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="country"
                    >
                      Choisir votre nationalite
                    </label>
                    <div className="col-sm-5">
                      <Field
                        as="select"
                        className="form-control"
                        name="country"
                        id="country"
                      >
                        <option value="">Choisir dans la liste</option>
                        {countries &&
                          countries.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage name="company" />
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>Compte utilisateur</legend>
                <div>
                  <div className="row mb-3">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="telephone"
                    >
                      Telephone
                    </label>
                    <div className="col-sm-5">
                      <Field
                        className="form-control"
                        name="telephone"
                        type="text"
                      />
                      <ErrorMessage name="telephone" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label" htmlFor="email">
                      Email adresse
                    </label>
                    <div className="col-sm-5">
                      <Field
                        className="form-control"
                        name="email"
                        type="text"
                      />
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      className="col-sm-2 col-form-label"
                      htmlFor="password"
                    >
                      Mot de passe
                    </label>
                    <div className="col-sm-5">
                      <Field
                        className="form-control"
                        name="password"
                        type="password"
                      />
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="footer">
                <button
                  className="btn btn-primary mr-3"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Enregistrer
                </button>
                <Link to={isAddMode ? "." : ".."} className="btn btn-light">
                  Annuler
                </Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default CompanyForm;
