import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProfileImg from "../../images/profile.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";

import { GET_DOCTOR } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";
import { useParams } from "react-router-dom";

const DoctorDetail = () => {
  const { id } = useParams();
  const actions = [{ title: "Modifier", url: "/doctor/edit/" + id }];
  const [doctor, setDoctor] = useState({});

  const request = FetchWrapper();
  useEffect(() => {
    request.get(GET_DOCTOR + id).then((response) => {
      setDoctor(response);
    });
  }, []);

  return (
    <div className="Profile">
      <div className="Profile__Wrapper">
        <div className="Profile__Header">
          <div className="Profile__Cover">
            <div className="Profile__CoverInfo">
              <a href=".">
                <ArrowBackIcon className="Icon" />
              </a>
              <div className="Profile__Info">
                <div className="Profile__Left">
                  <img src={ProfileImg} />
                </div>
                <div className="Profile__Right">
                  <h3>{doctor.firstName + " " + doctor.lastName}</h3>
                  {doctor.role === "user" ? <p>Simple utilisateur</p> : ""}
                  {doctor.role === "patient" ? <p>Patient</p> : ""}
                  {doctor.role === "admin" ? <p>Administrateur</p> : ""}
                  {doctor.role === "doctor" ? <p>Docteur</p> : ""}
                </div>
              </div>
            </div>
            <div className="Profile__CoverActions">
              <a className="Cover__Button" href={`/doctor/edit/${id}`}>
                Modifier mon compte
              </a>
            </div>
          </div>
          <div className="Profile__Nav">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#account">
                  Compte utilisateur
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Profile__Content">
          <fieldset id="account">
            <legend>Mon compte</legend>
            <div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <ShieldOutlinedIcon className="ProfileIcon" />
                  <span>Role</span>
                </div>
                <div className="col-3 Info">
                  {doctor.role === "user" ? (
                    <span>Simple utilisateur</span>
                  ) : (
                    ""
                  )}
                  {doctor.role === "patient" ? <span>Patient</span> : ""}
                  {doctor.role === "admin" ? <span>Administrateur</span> : ""}
                  {doctor.role === "doctor" ? <span>Docteur</span> : ""}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <EmailOutlinedIcon className="ProfileIcon" />
                  <span>Adresse Email</span>
                </div>
                <div className="col-3 Info">{doctor.email}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <CallOutlinedIcon className="ProfileIcon" />
                  <span>Telephone</span>
                </div>
                <div className="col-3 Info">{doctor.telephone}</div>
              </div>
            </div>
          </fieldset>
          <fieldset id="profile">
            <legend>Mon Profile</legend>
            <div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <PersonOutlinedIcon className="ProfileIcon" />
                  <span>Nom</span>
                </div>
                <div className="col-3 Info">{doctor.firstName}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <PersonOutlinedIcon className="ProfileIcon" />
                  <span>Prenoms</span>
                </div>
                <div className="col-3 Info">{doctor.lastName}</div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default DoctorDetail;
