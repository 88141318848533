import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { alertService } from "../../services/AlertService";
import { FetchWrapper } from "../../http/apiRequests";
import {
  ADD_HOSPITAL,
  EDIT_HOSPITAL,
  GET_HOSPITAL,
} from "../../constants/apiEndpoints";
import FormLayout from "../../layouts/FormLayout";

function HospitalForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const actions = [{ url: "", title: "Liste des utilisateurs" }];

  const isAddMode = !id;
  const request = FetchWrapper();

  const initialValues = {
    name: "",
    description: "",
    telephone: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Champ requis!"),
    description: Yup.string().required("Champ requis!"),
    telephone: Yup.string().required("Champ requis!"),
    email: Yup.string()
      .email("Adresse Email invalide!")
      .required("Champ requis!"),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createUser(fields, setSubmitting);
    } else {
      updateUser(id, fields, setSubmitting);
    }
  }

  function createUser(fields, setSubmitting) {
    request
      .post(ADD_HOSPITAL, fields)
      .then(() => {
        alertService.success("Données créées avec succès", {
          keepAfterRouteChange: true,
        });
        navigate("");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateUser(id, fields, setSubmitting) {
    request
      .post(EDIT_HOSPITAL + id, fields)
      .then(() => {
        alertService.success("Données mises à jour avec succès", {
          keepAfterRouteChange: true,
        });
        navigate("");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <FormLayout
      title={isAddMode ? "Ajouter un hôpital" : "Modifier l'hôpital"}
      path={isAddMode ? "." : ".."}
      description="Veuillez renseigner les informations ci-dessous"
      actions={actions}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {function Render({ errors, touched, isSubmitting, setFieldValue }) {
          const [user, setUser] = useState({});
          const [showPassword, setShowPassword] = useState(false);

          useEffect(() => {
            if (!isAddMode) {
              // get user and set form fields
              request.get(GET_HOSPITAL + id).then((user) => {
                console.log("hello");
                const fields = ["name", "description", "telephone", "email"];
                fields.forEach((field) =>
                  setFieldValue(field, user[field], false)
                );
                setUser(user);
              });
            }
          }, []);

          return (
            <Form>
              <fieldset>
                <legend>Informations</legend>
                <div>
                  <div className="row">
                    <div className="col-3">
                      <label>Nom de l'hôpital</label>
                    </div>
                    <div className="col-5">
                      <Field
                        name="name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <label>Description</label>
                    </div>
                    <div className="col-5">
                      <Field
                        name="description"
                        as="textarea"
                        className={
                          "form-control" +
                          (errors.description && touched.description
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <label>Telephone</label>
                    </div>
                    <div className="col-5">
                      <Field
                        name="telephone"
                        type="text"
                        className={
                          "form-control" +
                          (errors.telephone && touched.telephone
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="telephone"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-3">
                      <label>Email</label>
                    </div>
                    <div className="col-5">
                      <Field
                        name="email"
                        type="text"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="row">
                <div className="col-12">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Enregistrer
                  </button>
                  <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                    Annuler
                  </Link>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
}

export default HospitalForm;
