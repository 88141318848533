import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { GET_USER, ADD_USER, EDIT_USER } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";
import { alertService } from "../../services/AlertService";
import FormLayout from "../../layouts/FormLayout";

function UserForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const actions = [{ url: "", title: "Liste des utilisateurs" }];
  const request = FetchWrapper();

  const isAddMode = !id;
  const [user, setUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    role: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Champ est requis"),
    firstName: Yup.string().required("Champ est requis"),
    lastName: Yup.string().required("Champ est requis"),
    telephone: Yup.string().required("Champ est requis"),
    email: Yup.string()
      .email("Adresse Email invalide")
      .required("Champ est requis"),
    role: Yup.string().required("Champ est requis"),
    password: Yup.string()
      .concat(isAddMode ? Yup.string().required("Champ est requis") : null)
      .min(6, "doit être composé de 6 caractères"),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password || isAddMode) return schema.required("Champ est requis");
      })
      .oneOf([Yup.ref("password")], "Les mots de passe doivent correspondre"),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createUser(fields, setSubmitting);
    } else {
      updateUser(id, fields, setSubmitting);
    }
  }

  const createUser = async (fields, setSubmitting) => {
    try {
      const response = await request.post(ADD_USER, fields);
      if (response) {
        alertService.success("nouvelles informations ajoutées", {
          keepAfterRouteChange: true,
        });
        navigate("/users");
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error);
    }
  };

  const updateUser = async (id, fields, setSubmitting) => {
    try {
      const response = await request.post(EDIT_USER + id, fields);
      if (response) {
        alertService.success("Informations modifiées", {
          keepAfterRouteChange: true,
        });
        navigate("/users");
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error);
    }
  };

  return (
    <FormLayout
      title={isAddMode ? "Nouvel Utilisateur" : "Modifier l'utilisateur"}
      path={isAddMode ? "." : ".."}
      description="Veuillez renseigner les informations ci-dessous"
      actions={actions}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {function Render({ errors, touched, isSubmitting, setFieldValue }) {
          useEffect(async () => {
            if (!isAddMode) {
              const response = await request.get(GET_USER + id);
              setUser(response);

              if (response) {
                const fields = [
                  "title",
                  "firstName",
                  "lastName",
                  "email",
                  "telephone",
                  "role",
                ];
                fields.forEach((field) =>
                  setFieldValue(field, response[field], false)
                );
              }
            }
          }, []);

          return (
            <Form>
              <fieldset>
                <legend>Informations Générales</legend>
                <div className="row">
                  <div className="col">
                    <label>Nom</label>
                    <Field
                      name="firstName"
                      type="text"
                      className={
                        "form-control" +
                        (errors.firstName && touched.firstName
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col">
                    <label>Prénoms</label>
                    <Field
                      name="lastName"
                      type="text"
                      className={
                        "form-control" +
                        (errors.lastName && touched.lastName
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Adresse email</label>
                    <Field
                      name="email"
                      type="text"
                      className={
                        "form-control" +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col">
                    <label>Téléphone</label>
                    <Field
                      name="telephone"
                      type="text"
                      className={
                        "form-control" +
                        (errors.telephone && touched.telephone
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="telephone"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Role</label>
                    <Field
                      name="role"
                      as="select"
                      className={
                        "form-control" +
                        (errors.role && touched.role ? " is-invalid" : "")
                      }
                    >
                      <option value=""></option>
                      <option value="user">Simple utilisateur</option>
                      <option value="admin">Administrateur</option>
                      <option value="patient">Patient</option>
                      <option value="doctor">Docteur</option>
                    </Field>
                    <ErrorMessage
                      name="role"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Compte utilisateur</legend>
                {!isAddMode && (
                  <div>
                    <p>Laissez vide pour garder le même mot de passe</p>
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <label>Mot de passe</label>
                    <Field
                      name="password"
                      type="password"
                      className={
                        "form-control" +
                        (errors.password && touched.password
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col">
                    <label>Confirmez le mot de passe</label>
                    <Field
                      name="confirmPassword"
                      type="password"
                      className={
                        "form-control" +
                        (errors.confirmPassword && touched.confirmPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </fieldset>

              <div className="row">
                <div className="col footer">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-lg btn-primary"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Enregistrer
                  </button>
                  <Link
                    to={isAddMode ? "." : ".."}
                    className="btn btn-lg btn-light"
                  >
                    Annuler
                  </Link>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
}

export default UserForm;
