import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import { GET_USER_LIST, REMOVE_USER } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

const UserList = () => {
  const { path } = useParams();
  const actions = [{ url: "new", title: "Creer un utilisateur" }];

  const request = FetchWrapper();
  const [users, setUsers] = useState(null);

  useEffect(async () => {
    const response = await request.get(GET_USER_LIST);
    setUsers(response);
  }, []);

  const deleteUser = async (id) => {
    setUsers(
      users.map((x) => {
        if (x._id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    const response = await request.post(REMOVE_USER + id);
    if (response) {
      setUsers((users) => users.filter((x) => x._id !== id));
    }
  };

  return (
    <PageLayout
      title="Utilisateurs"
      description="liste de tous les utilisateurs"
      actions={actions}
    >
      <div>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Nom</th>
              <th style={{ width: "30%" }}>Email</th>
              <th style={{ width: "30%" }}>Role</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => (
                <tr key={user._id}>
                  <td>
                    {user.title} {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <div className="ButtonIconContainer">
                      <Link className="ButtonIcon" to={`${user._id}`}>
                        <ContentPasteSearchOutlinedIcon />
                      </Link>
                      <Link className="ButtonIcon" to={`edit/${user._id}`}>
                        <ModeEditOutlineOutlinedIcon />
                      </Link>
                      <button
                        onClick={() => deleteUser(user._id)}
                        className="ButtonIcon"
                        disabled={user.isDeleting}
                      >
                        {user.isDeleting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <DeleteOutlineOutlinedIcon />
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            {!users && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="spinner-border spinner-border-lg align-center"></div>
                </td>
              </tr>
            )}
            {users && !users.length && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="p-2">No Users To Display</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </PageLayout>
  );
};

export default UserList;
