import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_HOSPITAL, GET_HOSPITAL_LIST } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import PageLayout from "../../layouts/PageLayout";

const HospitalList = () => {
  const actions = [{ url: "new", title: "Creer un etablissement sanitaire" }];

  const [hospitals, setHospitals] = useState([]);

  const request = FetchWrapper();

  useEffect(() => {
    request.get(GET_HOSPITAL_LIST).then((x) => setHospitals(x));
  }, []);

  function deleteHospital(id) {
    setHospitals(
      hospitals.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    request.remove(GET_HOSPITAL + id).then(() => {
      setHospitals((hospitals) => hospitals.filter((x) => x.id !== id));
    });
  }
  return (
    <PageLayout title="Etablissements sanitaires" actions={actions}>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Nom</th>
              <th style={{ width: "30%" }}>Email</th>
              <th style={{ width: "30%" }}>Telephone</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {hospitals &&
              hospitals.map((hospital) => (
                <tr key={hospital.id}>
                  <td>{hospital.name}</td>
                  <td>{hospital.telephone}</td>
                  <td>{hospital.email}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <div className="ButtonIconContainer">
                      <Link className="ButtonIcon" to={`${hospital.id}`}>
                        <ContentPasteSearchOutlinedIcon />
                      </Link>
                      <Link className="ButtonIcon" to={`edit/${hospital.id}`}>
                        <ModeEditOutlineOutlinedIcon />
                      </Link>
                      <button
                        onClick={() => deleteHospital(hospital.id)}
                        className="ButtonIcon"
                        disabled={hospital.isDeleting}
                      >
                        {hospital.isDeleting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <DeleteOutlineOutlinedIcon />
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            {!hospitals && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="spinner-border spinner-border-lg align-center"></div>
                </td>
              </tr>
            )}
            {hospitals && !hospitals.length && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="p-2">No Hospitals To Display</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </PageLayout>
  );
};

export default HospitalList;
