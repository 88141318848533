import React, { useState, useEffect } from "react";
import DetailLayout from "../../layouts/DetailLayout";
import { GET_COMPANY, GET_SERVICE } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";
import { useParams } from "react-router-dom";

const ServiceDetail = () => {
  const { id } = useParams();
  const actions = [{ title: "Modifier", url: "/service/edit/" + id }];
  const [service, setService] = useState({});
  const request = FetchWrapper();
  useEffect(async () => {
    const response = await request.get(GET_SERVICE + id);
    setService(response);
  }, []);
  return (
    <DetailLayout
      title={service.title}
      description="some statistics about the service"
      actions={actions}
    >
      <div className="row">
        <div className="col">Nom de la societe</div>
        <div className="col">{service.title}</div>
      </div>
      <br />
      <div className="row">
        <div className="col">Description</div>
        <div className="col">{service.description}</div>
      </div>
    </DetailLayout>
  );
};

export default ServiceDetail;
