import React, { useState, useEffect } from "react";
import FormLayout from "../../layouts/FormLayout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { MyTextInput, MyCheckbox } from "../Inputs";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { alertService } from "../../services/AlertService";

import {
  ADD_DOCTOR,
  EDIT_DOCTOR,
  GET_DOCTOR,
  GET_HOSPITAL_LIST,
  GET_SERVICE_LIST,
} from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";

import * as Yup from "yup";

const HospitalForm = () => {
  const request = FetchWrapper();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = !id;

  const [hospitals, setHospitals] = useState([]);
  const [services, setServices] = useState([]);
  const [doctor, setDoctor] = useState({});

  useEffect(async () => {
    const serviceResponse = await request.get(GET_SERVICE_LIST);
    setServices(serviceResponse);

    const hospitalResponse = await request.get(GET_HOSPITAL_LIST);
    setHospitals(hospitalResponse);
  }, []);

  const initialValues = {
    role: "doctor",
    hospital: "",
    service: "",
    firstName: "",
    lastName: "",
    fonction: "",
    telephone: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    hospital: Yup.string().required("Selectionner le nom de la societe"),
    service: Yup.string().required("Selectionner le service"),
    firstName: Yup.string().required("Renseigner un nom"),
    lastName: Yup.string().required("Renseigner un prenoms"),
    fonction: Yup.string().required("Renseigner la fonction"),
    telephone: Yup.string().required("Renseigner le numero de telephone"),
    email: Yup.string().required("Renseigner l'adresse email"),
  });

  const handleSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus();
    if (isAddMode) {
      createDoctor(values, setSubmitting);
    } else {
      updateDoctor(id, values, setSubmitting);
    }
  };

  const createDoctor = async (values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(ADD_DOCTOR, values);
      if (response) {
        alertService.success("Nouveau docteur creer", {
          keepAfterRouteChange: true,
        });
        const { from } = location.state || {
          from: { pathname: "." },
        };
        navigate(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  const updateDoctor = async (id, values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(EDIT_DOCTOR + id, values);
      if (response) {
        alertService.success("Mise a jour effectuer", {
          keepAfterRouteChange: true,
        });
        const { from } = location.state || {
          from: { pathname: ".." },
        };
        navigate(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  return (
    <FormLayout
      title={
        isAddMode ? "Ajouter un docteur" : "Modifier les infos. du docteur"
      }
      path={isAddMode ? "/doctor" : `/doctor/${id}`}
      description="veuillez renseigner les informations ci-dessous"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {function Render({ errors, touched, isSubmitting, setFieldValue }) {
          useEffect(async () => {
            if (!isAddMode) {
              alert();
              const response = await request.get(GET_DOCTOR + id);
              setDoctor(response);
              if (response) {
                const fields = [
                  "hospital",
                  "service",
                  "firstName",
                  "lastName",
                  "fonction",
                  "telephone",
                  "email",
                ];
                fields.forEach((field) => {
                  if (field === "date") {
                    let date = new Date(response[field]);
                    let day = date.getDate();
                    let month = date.getMonth() + 1;
                    let year = date.getFullYear();
                    setFieldValue(field, year + "-" + month + "-" + day, false);
                  } else if (field === "hospital" || field === "service") {
                    console.log(response[field]);
                    setFieldValue(field, response[field].id, false);
                  } else {
                    setFieldValue(field, response[field], false);
                  }
                });
              }
            }
          }, []);

          return (
            <Form>
              <fieldset>
                <legend>Infos. persos.</legend>

                <div className="row mb-3">
                  <label
                    className="col-sm-2 col-form-label"
                    htmlFor="firstName"
                  >
                    Nom
                  </label>
                  <div className="col-sm-5">
                    <Field
                      className="form-control"
                      name="firstName"
                      placeholder="Nom de famille"
                      type="text"
                    />
                    <ErrorMessage name="firstName" />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    className="col-sm-2 col-form-label"
                    htmlFor="firstName"
                  >
                    Prenoms
                  </label>
                  <div className="col-sm-5">
                    <Field
                      className="form-control"
                      placeholder="Prenoms"
                      name="lastName"
                      type="text"
                    />
                    <ErrorMessage name="lastName" />
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>Compte utilisateur</legend>
                <div className="row mb-3">
                  <label
                    className="col-sm-2 col-form-label"
                    htmlFor="telephone"
                  >
                    Telephone
                  </label>
                  <div className="col-sm-5">
                    <Field
                      className="form-control"
                      name="telephone"
                      type="text"
                    />
                    <ErrorMessage name="telephone" />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="email">
                    Email adresse
                  </label>
                  <div className="col-sm-5">
                    <Field className="form-control" name="email" type="text" />
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="password">
                    Mot de passe
                  </label>
                  <div className="col-sm-5">
                    <Field
                      className="form-control"
                      name="password"
                      type="password"
                    />
                    <ErrorMessage name="password" />
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>Travaille</legend>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="hospital">
                    Nom de la societe
                  </label>
                  <div className="col-sm-5">
                    <Field
                      as="select"
                      className="form-control"
                      name="hospital"
                      id="hospital"
                    >
                      <option value="">Choisir dans la liste</option>
                      {hospitals &&
                        hospitals.map((hospital, index) => (
                          <option key={index} value={hospital.id}>
                            {hospital.name}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage name="hospital" />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="service">
                    Service
                  </label>
                  <div className="col-sm-5">
                    <Field
                      as="select"
                      className="form-control"
                      name="service"
                      id="service"
                    >
                      <option value="">Choisir dans la liste</option>
                      {services &&
                        services.map((service, index) => (
                          <option key={index} value={service.id}>
                            {service.title}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage name="service" />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="fonction">
                    Fonction
                  </label>
                  <div className="col-sm-5">
                    <Field
                      className="form-control"
                      name="fonction"
                      type="text"
                    />
                    <ErrorMessage name="fonction" />
                  </div>
                </div>
              </fieldset>

              <div>
                <button
                  className="btn btn-primary mr-3"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Enregistrer
                </button>
                <Link to={isAddMode ? "." : ".."} className="btn btn-light">
                  Annuler
                </Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default HospitalForm;
