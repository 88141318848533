import React, { useState, useEffect } from "react";
import { GET_REQUEST } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AirlineSeatFlatOutlinedIcon from "@material-ui/icons/AirlineSeatFlatOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import { formatToDate } from "../../helpers/utility";
import RequestValidate from "./RequestValidate";
import { useParams } from "react-router-dom";

const RequestDetail = () => {
  const { id } = useParams();
  const actions = [{ title: "Modifier", url: "/request/edit/" + id }];
  const [request, setRequest] = useState({});
  const fetchWrapper = FetchWrapper();
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await fetchWrapper.get(GET_REQUEST + id);
      setRequest(response);
    }
    fetchData();
  }, []);

  return (
    <div className="Profile">
      <div className="Profile__Wrapper">
        <div className="Profile__Header">
          <div className="Profile__Cover">
            <div className="Profile__CoverInfo">
              <a href=".">
                <ArrowBackIcon className="Icon" />
              </a>
              <div className="Profile__Info">
                <div>
                  <h3>{request.motif}</h3>
                  <p>{formatToDate(request.date)}</p>
                </div>
              </div>
            </div>
            <div className="Profile__CoverActions">
              {request.status === "pending" ? (
                <div className="widgetLgButton Pending">EN ATTENTE</div>
              ) : (
                ""
              )}
              {request.status === "accepted" ? (
                <div className="widgetLgButton Approved">VALIDER</div>
              ) : (
                ""
              )}
              {request.status === "cancelled" ? (
                <div className="widgetLgButton Declined">ANNULER</div>
              ) : (
                ""
              )}

              {request.status === "pending" ? (
                <button className="Cover__Button" onClick={() => setShow(true)}>
                  Statuer sur la demande
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="Profile__Nav">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#rdv">
                  Informations
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Profile__Content">
          <fieldset id="rdv">
            <legend>Informations</legend>
            <div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <AirlineSeatFlatOutlinedIcon className="ProfileIcon" />
                  <span>Motif</span>
                </div>
                <div className="col-3 Info">{request.motif}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <EventAvailableOutlinedIcon className="ProfileIcon" />
                  <span>Date</span>
                </div>
                <div className="col-3 Info">{formatToDate(request.date)}</div>
              </div>
            </div>
          </fieldset>
          {request.patient && (
            <fieldset id="patient">
              <legend>Patient</legend>
              <div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    <span>Nom du patient</span>
                  </div>
                  <div className="col-3 Info">{request.patient.firstName}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    <span>Prenoms</span>
                  </div>
                  <div className="col-3 Info">{request.patient.lastName}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <EmailOutlinedIcon className="ProfileIcon" />
                    <span>Email</span>
                  </div>
                  <div className="col-3 Info">{request.patient.email}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <CallOutlinedIcon className="ProfileIcon" />
                    <span>Telephone</span>
                  </div>
                  <div className="col-3 Info">{request.patient.telephone}</div>
                </div>
              </div>
            </fieldset>
          )}

          {request.doctor && (
            <fieldset id="doctor">
              <legend>Docteur</legend>
              <div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    <span>Nom</span>
                  </div>
                  <div className="col-3 Info">{request.doctor.firstName}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    <span>Prenoms</span>
                  </div>
                  <div className="col-3 Info">{request.doctor.lastName}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <EmailOutlinedIcon className="ProfileIcon" />
                    <span>Email</span>
                  </div>
                  <div className="col-3 Info">{request.doctor.email}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <CallOutlinedIcon className="ProfileIcon" />
                    <span>Telephone</span>
                  </div>
                  <div className="col-3 Info">{request.doctor.telephone}</div>
                </div>
              </div>
            </fieldset>
          )}
        </div>
      </div>
      <RequestValidate
        title="Statuer sur la demande"
        id={id}
        onClose={() => setShow(false)}
        callback={async () => {
          const response = await fetchWrapper.get(GET_REQUEST + id);
          setRequest(response);
        }}
        show={show}
      />
    </div>
  );
};

export default RequestDetail;
