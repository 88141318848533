import React from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";

import { Alert } from "./components/Alert";
import Login from "./pages/login/Login";
import PrivateRoute from "./components/PrivateRoute";

import Home from "./pages/home/Home";
import NotificationList from "./components/Notification/NotificationList";
import RequestValidate from "./components/Request/RequestValidate";
import RequestDetail from "./components/Request/RequestDetail";
import RequestList from "./components/Request/RequestList";
import AppointmentForm from "./components/Appointment/AppointmentForm";
import AppointmentList from "./components/Appointment/AppointmentList";
import AppointmentDetail from "./components/Appointment/AppointmentDetail";
import HospitalForm from "./components/Hospital/HospitalForm";
import HospitalDetail from "./components/Hospital/HospitalDetail";
import HospitalList from "./components/Hospital/HospitalList";
import UserForm from "./components/User/UserForm";
import UserDetail from "./components/User/UserDetail";
import UserList from "./components/User/UserList";
import DoctorForm from "./components/Doctor/DoctorForm";
import DoctorDetail from "./components/Doctor/DoctorDetail";
import DoctorList from "./components/Doctor/DoctorList";
import PatientList from "./components/Patient/PatientList";
import PatientForm from "./components/Patient/PatientForm";
import PatientDetail from "./components/Patient/PatientDetail";
import ServiceForm from "./components/Service/ServiceForm";
import ServiceList from "./components/Service/ServiceList";
import ServiceDetail from "./components/Service/ServiceDetail";

function App() {
  return (
    <>
      <Alert />
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<Home />} />
          <Route path="notifications" element={<NotificationList />} />
          <Route path="request">
            <Route path="validate/:id" element={<RequestValidate />} />
            <Route path=":id" element={<RequestDetail />} />
            <Route path="" element={<RequestList />} />
          </Route>
          <Route path="rdv">
            <Route path="new" element={<AppointmentForm />} />
            <Route path="edit/:id" element={<AppointmentForm />} />
            <Route path=":id" element={<AppointmentDetail />} />
            <Route path="" element={<AppointmentList />} />
          </Route>
          <Route path="hospital">
            <Route path="edit/:id" element={<HospitalForm />} />
            <Route path="new" element={<HospitalForm />} />
            <Route path=":id" element={<HospitalDetail />} />
            <Route path="" element={<HospitalList />} />
          </Route>
          <Route path="users">
            <Route path="edit/:id" element={<UserForm />} />
            <Route path="new" element={<UserForm />} />
            <Route path=":id" element={<UserDetail />} />
            <Route path="" element={<UserList />} />
          </Route>
          <Route path="doctor">
            <Route path="edit/:id" element={<DoctorForm />} />
            <Route path="new" element={<DoctorForm />} />
            <Route path=":id" element={<DoctorDetail />} />
            <Route path="" element={<DoctorList />} />
          </Route>
          <Route path="patient">
            <Route path="edit/:id" element={<PatientForm />} />
            <Route path="new" element={<PatientForm />} />
            <Route path=":id" element={<PatientDetail />} />
            <Route path="" element={<PatientList />} />
          </Route>
          <Route path="service">
            <Route path="edit/:id" element={<ServiceForm />} />
            <Route path="new" element={<ServiceForm />} />
            <Route path=":id" element={<ServiceDetail />} />
            <Route path="" element={<ServiceList />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<>Not Found</>} />
      </Routes>
    </>
  );
}

export default App;
