import React, { useState, useEffect } from "react";
import { FetchWrapper } from "../../http/apiRequests";
import { GET_HOSPITAL } from "../../constants/apiEndpoints";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProfileImg from "../../images/profile.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import { useParams } from "react-router-dom";

const HospitalDetail = () => {
  const { id } = useParams();
  const request = FetchWrapper();
  const [hospital, setHospital] = useState({});

  useEffect(() => {
    request.get(GET_HOSPITAL + id).then((hospital) => {
      setHospital(hospital);
    });
  }, []);

  return (
    <div className="Profile">
      <div className="Profile__Wrapper">
        <div className="Profile__Header">
          <div className="Profile__Cover">
            <div className="Profile__CoverInfo">
              <a href=".">
                <ArrowBackIcon className="Icon" />
              </a>
              <div className="Profile__Info">
                <div className="Profile__Left">
                  <img src={ProfileImg} />
                </div>
                <div className="Profile__Right">
                  <h3>{hospital.name}</h3>
                  <p>{hospital.description}</p>
                </div>
              </div>
            </div>
            <div className="Profile__CoverActions">
              <a
                className="Cover__Button"
                href={`/hospital/edit/${hospital.id}`}
              >
                Modifier
              </a>
            </div>
          </div>
          <div className="Profile__Nav">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="#description"
                >
                  Description
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Profile__Content">
          <fieldset id="description">
            <legend>Informations</legend>
            <div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <PersonOutlinedIcon className="ProfileIcon" />
                  <span>Nom</span>
                </div>
                <div className="col-3 Info">{hospital.name}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <PersonOutlinedIcon className="ProfileIcon" />
                  <span>Desc.</span>
                </div>
                <div className="col-3 Info">{hospital.description}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <EmailOutlinedIcon className="ProfileIcon" />
                  <span>Adresse Email</span>
                </div>
                <div className="col-3 Info">{hospital.email}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <CallOutlinedIcon className="ProfileIcon" />
                  <span>Telephone</span>
                </div>
                <div className="col-3 Info">{hospital.telephone}</div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default HospitalDetail;
