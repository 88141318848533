import React, { useState, useEffect } from "react";
import { FetchWrapper } from "../../http/apiRequests";
import { UNREAD_USER_NOTIFICATIONS } from "../../constants/apiEndpoints";

const NotificationList = () => {
  const [msgs, setMsgs] = useState([]);
  const request = FetchWrapper();

  useEffect(async () => {
    const messages = await request.post(UNREAD_USER_NOTIFICATIONS, {});
    setMsgs(messages);
  }, []);
  return <div></div>;
};

export default NotificationList;
