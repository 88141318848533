import React, { useState, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";

import { Link } from "react-router-dom";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import ProfileImg from "../../images/profile.png";
import DocImg from "../../images/Doc.png";

import {
  GET_APPOINTMENT_LIST,
  REMOVE_APPOINTMENT,
} from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";
import { formatToDate } from "../../helpers/utility";

const AppointmentList = () => {
  const actions = [{ url: "new", title: "Nouveau RDV" }];

  const request = FetchWrapper();
  const [rows, setRows] = useState([]);

  useEffect(async () => {
    const response = await request.get(GET_APPOINTMENT_LIST);
    setRows(response);
  }, []);

  const handleDelete = async (id) => {
    setRows(
      rows.map((x) => {
        if (x.id === id) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    const response = await request.remove(REMOVE_APPOINTMENT + id, { id });
    if (response) {
      setRows((rows) => rows.filter((x) => x.id !== id));
    }
  };

  return (
    <PageLayout
      title="Liste des RDVs"
      description="liste de tous les Rendez-vous"
      actions={actions}
    >
      <div>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Nom</th>
              <th style={{ width: "30%" }}>Motif / Date</th>
              <th style={{ width: "30%" }}>Docteur</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((row) => (
                <tr key={row.id}>
                  <td>
                    <div className="PersonCard">
                      <div className="PersonCard__ImgContainer">
                        <img src={ProfileImg} alt="" />
                      </div>
                      <div className="PersonCard__Info">
                        <div className="PersonCard__PrimaryText">
                          {row.patient.firstName}
                        </div>
                        <div className="PersonCard__SecondaryText">
                          {row.patient.lastName}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {row.motif} / {formatToDate(row.date)}
                  </td>
                  <td>
                    <div className="PersonCard">
                      <div className="PersonCard__ImgContainer">
                        <img src={DocImg} alt="" />
                      </div>
                      <div className="PersonCard__Info">
                        <div className="PersonCard__PrimaryText">
                          {row.doctor.firstName}
                        </div>
                        <div className="PersonCard__SecondaryText">
                          {row.doctor.lastName}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <div className="ButtonIconContainer">
                      <Link className="ButtonIcon" to={`${row.id}`}>
                        <ContentPasteSearchOutlinedIcon />
                      </Link>

                      {row.status === false ? (
                        <>
                          <Link className="ButtonIcon" to={`edit/${row.id}`}>
                            <ModeEditOutlineOutlinedIcon />
                          </Link>
                          <button
                            onClick={() => handleDelete(row.id)}
                            className="ButtonIcon"
                            disabled={row.isDeleting}
                          >
                            {row.isDeleting ? (
                              <span className="spinner-border spinner-border-sm"></span>
                            ) : (
                              <DeleteOutlineOutlinedIcon />
                            )}
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            {!rows && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="spinner-border spinner-border-lg align-center"></div>
                </td>
              </tr>
            )}
            {rows && !rows.length && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="p-2">Aucun enregistrement</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </PageLayout>
  );
};

export default AppointmentList;
