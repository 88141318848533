import React, { useContext } from "react";
import "./Login.scss";

import { MyTextInput, MyCheckbox } from "../../components/Inputs";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { alertService } from "../../services/AlertService";
import { history } from "../../helpers/history";

import { AuthContext } from "../../context/AuthContext";

import axios from "axios";
import { LOGIN } from "../../constants/apiEndpoints";

import * as Yup from "yup";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const { user, isFetching, dispatch, error } = useContext(AuthContext);

  const initialValues = {
    role: "doctor",
    email: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Cette adresse email est invalide")
      .required("Required"),
    password: Yup.string().required("Renseigner le mot de passe"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    alertService.clear();
    dispatch({ type: "LOGIN_START" });
    try {
      const response = await axios.post(LOGIN, values);
      alertService.success("...", { keepAfterRouteChange: true });
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
      navigate("/");
    } catch (error) {
      alertService.error("Identifiant incorrecte veuillez reesayer");
      setSubmitting(false);
      dispatch({ type: "LOGIN_FAILURE", payload: error });
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <div className="Sign">
      <div className="Sign__Card">
        <div className="Sign__Content">
          <h1>Déjà un compte</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <MyTextInput
                name="email"
                type="email"
                placeholder="Email Address"
              />
              <MyTextInput
                name="password"
                type="password"
                placeholder="Mot de passe"
              />
              <MyCheckbox name="acceptedTerms">
                Se souvenir de mon identifiant
              </MyCheckbox>

              <button type="submit">SE CONNECTER</button>
            </Form>
          </Formik>
          <div className="Sign__Footer">
            <a href="/forgotpass">Mot de passe oublié ?</a>
          </div>
        </div>
      </div>
      <div className="Sign__Card">
        <div className="Sign__Content">
          <h1>Nouveau sur Telemed.</h1>
          <div className="Sign__Footer">
            <a href="/register" className="Blue__Link">
              S'Inscrire
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
