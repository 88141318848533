import React, { useContext, useState, useEffect } from "react";

import {
  LineStyle,
  PermIdentity,
  Settings,
  NotificationsNone,
} from "@material-ui/icons";
import { NavLink, useNavigate } from "react-router-dom";
import ProfileImg from "../../images/profile.png";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AccessibleIcon from "@mui/icons-material/Accessible";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";

import { AuthContext } from "../../context/AuthContext";
import { FetchWrapper } from "../../http/apiRequests";
import { UNREAD_USER_MESSAGES } from "../../constants/apiEndpoints";

export default function Sidebar() {
  const navigate = useNavigate();
  const [msgs, setMsgs] = useState([]);
  const { user, dispatch } = useContext(AuthContext);
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const request = FetchWrapper();

  useEffect(async () => {
    const messages = await request.get(UNREAD_USER_MESSAGES);
    setMsgs(messages);
  }, []);

  return (
    <div className="Sidebar">
      <div className="SidebarWrapper">
        <div className="Avatar">
          <div className="Avatar__Img">
            <img src={ProfileImg} alt="" />
          </div>
          <div className="Avatar__Info">
            <h4>{user.profile.fullName}</h4>
            <p>{user.profile.email}</p>
          </div>
          <div className="Avatar__Actions">
            <NavLink to="/" className="Link" exact={true}>
              <li className="Item">
                <Settings className="Icon" />
              </li>
            </NavLink>
            <NavLink to="/notifications" className="Link" exact={true}>
              <li className="Item">
                <NotificationsNone className="Icon" />
                {msgs && <span className="Badge">{msgs.msgs_count}</span>}
              </li>
            </NavLink>
            <NavLink to="/" className="Link">
              <li className="Item" onClick={handleLogout}>
                <LogoutIcon className="Icon" />
              </li>
            </NavLink>
          </div>
        </div>
        <div className="SidebarMenu">
          <ul className="SidebarList">
            <NavLink to="/" className="link" exact={true}>
              <li className="SidebarListItem">
                <LineStyle className="SidebarIcon" />
                Dashboard
              </li>
            </NavLink>
            <NavLink to="/request" className="link">
              <li className="SidebarListItem">
                <AssignmentOutlinedIcon className="SidebarIcon" />
                Demandes de Rdvs
              </li>
            </NavLink>
            <NavLink to="/users" className="link">
              <li className="SidebarListItem">
                <PermIdentity className="SidebarIcon" />
                Utilisateurs
              </li>
            </NavLink>
            <NavLink to="/hospital" className="link">
              <li className="SidebarListItem">
                <LocalHospitalIcon className="SidebarIcon" />
                Etablissements de sante
              </li>
            </NavLink>
            <NavLink to="/doctor" className="link">
              <li className="SidebarListItem">
                <GroupOutlinedIcon className="SidebarIcon" />
                Docteurs
              </li>
            </NavLink>
            <NavLink to="/patient" className="link">
              <li className="SidebarListItem">
                <AccessibleIcon className="SidebarIcon" />
                Patients
              </li>
            </NavLink>
            <NavLink to="/rdv" className="link">
              <li className="SidebarListItem">
                <AssignmentTurnedInOutlinedIcon className="SidebarIcon" />
                Rendez-vous
              </li>
            </NavLink>
            <NavLink to="/service" className="link">
              <li className="SidebarListItem">
                <MedicalServicesOutlinedIcon className="SidebarIcon" />
                Service Medicale
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}
