import React, { useState, useEffect } from "react";
import PageLayout from "../../layouts/PageLayout";

import { Link } from "react-router-dom";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

import { GET_REQUEST_LIST, REMOVE_REQUEST } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";
import { formatToDate, formatToTime } from "../../helpers/utility";

const RequestList = () => {
  const request = FetchWrapper();
  const [rows, setRows] = useState([]);

  useEffect(async () => {
    const response = await request.get(GET_REQUEST_LIST);
    setRows(response);
  }, []);

  return (
    <PageLayout
      title="Demandes de Rdv"
      description="liste de toutes les demandes de Rendez-vous"
    >
      <div>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Nom du patient</th>
              <th style={{ width: "30%" }}>Motif / Date</th>
              <th style={{ width: "30%" }}>Statut</th>
              <th style={{ width: "10%" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows?.map((row) => (
                <tr key={row.id}>
                  <td>
                    {row.patient?.firstName} {row.patient?.lastName}
                  </td>
                  <td>
                    <div>{row.motif}</div>
                    <div>{formatToDate(row.date)}</div>
                  </td>
                  <td>
                    {row.status === "pending" ? (
                      <div className="widgetLgButton Pending">EN ATTENTE</div>
                    ) : (
                      ""
                    )}
                    {row.status === "accepted" ? (
                      <div className="widgetLgButton Approved">ACCEPTER</div>
                    ) : (
                      ""
                    )}
                    {row.status === "cancelled" ? (
                      <div className="widgetLgButton Declined">ANNULER</div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <div className="ButtonIconContainer">
                      <Link className="ButtonIcon" to={`${row.id}`}>
                        <ContentPasteSearchOutlinedIcon />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            {!rows && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="spinner-border spinner-border-lg align-center"></div>
                </td>
              </tr>
            )}
            {rows && !rows.length && (
              <tr>
                <td colSpan="4" className="text-center">
                  <div className="p-2">Aucun enregistrement</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </PageLayout>
  );
};

export default RequestList;
