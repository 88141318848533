import React from "react";
import "./PageLayout.css";

const PageLayout = ({ title, description, actions = [], children }) => {
  return (
    <div className="PageLayout">
      <div className="PageLayout__Wrapper">
        <div className="PageLayout__Header">
          <div className="PageLayout__HeaderTitle">
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div className="PageLayout__HeaderActions">
            {actions &&
              actions.map((action, index) => (
                <a
                  className="PageLayout__PrimayBtn"
                  key={index}
                  href={action.url}
                >
                  {action.title}
                </a>
              ))}
          </div>
        </div>
        <div className="PageLayout__Content">{children}</div>
      </div>
    </div>
  );
};

export default PageLayout;
