import React, { useState, useEffect } from "react";
import {
  GET_APPOINTMENT,
  REMOVE_APPOINTMENT,
} from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AirlineSeatFlatOutlinedIcon from "@material-ui/icons/AirlineSeatFlatOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import VideocamOffOutlinedIcon from "@material-ui/icons/VideocamOffOutlined";

import { formatToTime, formatToDate, countDown } from "../../helpers/utility";

import { Link, useNavigate, useParams } from "react-router-dom";

const CompanyDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const actions = [{ title: "Modifier", url: "appointment/edit/" + id }];
  const [appointment, setAppointment] = useState({});

  const [rDate, setRDate] = useState(0); //countdown 2 hours.
  const [rHour, setRHour] = useState(0);
  const [rMin, setRMin] = useState(0);
  const [rSec, setRSec] = useState(0);
  const [rExpired, setRExpired] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const request = FetchWrapper();

  useEffect(() => {
    async function fetchData() {
      const response = await request.get(GET_APPOINTMENT + id);
      setAppointment(response);
      setInterval(function () {
        const EndDate = new Date(response.date);
        let counter = countDown(EndDate);
        setRDate(counter.days);
        setRHour(counter.hours);
        setRMin(counter.minutes);
        setRSec(counter.seconds);
        setRExpired(counter.expired);
      }, 1000);
    }
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    appointment.isDeleting = true;
    const response = await request.remove(REMOVE_APPOINTMENT + id, { id });
    if (response) {
      navigate("/rdv");
    }
  };

  const handleUpload = () => {};

  const handleJoin = () => {
    window.open(
      "https://meet.fatihoune.com/room/" + id,
      "_blank",
      "toolbar=no,scrollbars=yes,resizable=yes,width=800,height=600"
    );
  };

  return (
    <div className="Profile">
      <div className="Profile__Wrapper">
        <div className="Profile__Header">
          <div className="Profile__Cover">
            <div className="Profile__CoverInfo">
              <a href=".">
                <ArrowBackIcon className="Icon" />
              </a>
              <div className="Profile__Info">
                <div>
                  <h3>{appointment.motif}</h3>
                  <p>
                    {formatToDate(appointment.date)}
                    {" - "}
                    {formatToTime(appointment.date)}
                  </p>
                </div>
              </div>
            </div>
            <div className="Profile__CoverActions">
              {appointment.status === false ? (
                <>
                  <div>
                    {rExpired ? (
                      <>
                        <button className="Cover__Button" onClick={handleJoin}>
                          <VideocamOutlinedIcon />
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="timer-container">
                          <div className="numbers">
                            <span className="num-span">{rDate} Jour(s)</span>
                            <span className="segment"> - </span>
                            <span className="num-span">{rHour} Heure(s)</span>
                            <span className="segment"> : </span>
                            <span className="num-span">{rMin} Minute(s)</span>
                            <span className="segment"> : </span>
                            <span className="num-span">{rSec} Seconde(s)</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Link className="Cover__Button" to={`edit/${id}`}>
                    <ModeEditOutlineOutlinedIcon />
                    Modifier
                  </Link>
                  <button
                    onClick={() => handleDelete(id)}
                    className="Cover__Button"
                    disabled={appointment.isDeleting}
                  >
                    {appointment.isDeleting ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <>
                        <DeleteOutlineOutlinedIcon />
                        Supprimer
                      </>
                    )}
                  </button>
                </>
              ) : (
                <>
                  <VideocamOffOutlinedIcon />
                </>
              )}
            </div>
          </div>
          <div className="Profile__Nav">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#rdv">
                  Informations
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#patient">
                  Patient
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#doctor">
                  Docteur
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Profile__Content">
          <fieldset id="rdv">
            <legend>Informations</legend>
            <div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <AirlineSeatFlatOutlinedIcon className="ProfileIcon" />
                  <span>Motif</span>
                </div>
                <div className="col-3 Info">{appointment.motif}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <EventAvailableOutlinedIcon className="ProfileIcon" />
                  <span>Date</span>
                </div>
                <div className="col-3 Info">
                  {formatToDate(appointment.date)}
                </div>
              </div>
            </div>
          </fieldset>
          {appointment.patient && (
            <fieldset id="patient">
              <legend>Patient</legend>
              <div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    <span>Nom du patient</span>
                  </div>
                  <div className="col-3 Info">
                    {appointment.patient.firstName}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    <span>Prenoms</span>
                  </div>
                  <div className="col-3 Info">
                    {appointment.patient.lastName}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <EmailOutlinedIcon className="ProfileIcon" />
                    <span>Email</span>
                  </div>
                  <div className="col-3 Info">{appointment.patient.email}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <CallOutlinedIcon className="ProfileIcon" />
                    <span>Telephone</span>
                  </div>
                  <div className="col-3 Info">
                    {appointment.patient.telephone}
                  </div>
                </div>
              </div>
            </fieldset>
          )}

          {appointment.doctor && (
            <fieldset id="doctor">
              <legend>Docteur</legend>
              <div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    <span>Nom</span>
                  </div>
                  <div className="col-3 Info">
                    {appointment.doctor.firstName}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <PersonOutlinedIcon className="ProfileIcon" />
                    <span>Prenoms</span>
                  </div>
                  <div className="col-3 Info">
                    {appointment.doctor.lastName}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <EmailOutlinedIcon className="ProfileIcon" />
                    <span>Email</span>
                  </div>
                  <div className="col-3 Info">{appointment.doctor.email}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-3 LabelIcon">
                    <CallOutlinedIcon className="ProfileIcon" />
                    <span>Telephone</span>
                  </div>
                  <div className="col-3 Info">
                    {appointment.doctor.telephone}
                  </div>
                </div>
              </div>
            </fieldset>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetail;
