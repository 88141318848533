import React, { useState, useEffect } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProfileImg from "../../images/profile.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import { GET_USER } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";
import { useParams } from "react-router-dom";

export default function User() {
  const { id } = useParams();
  const request = FetchWrapper();

  const [user, setUser] = useState({});

  useEffect(async () => {
    const response = await request.get(GET_USER + id);
    setUser(response);
  }, []);

  return (
    <div className="Profile">
      <div className="Profile__Wrapper">
        <div className="Profile__Header">
          <div className="Profile__Cover">
            <div className="Profile__CoverInfo">
              <a href=".">
                <ArrowBackIcon className="Icon" />
              </a>
              <div className="Profile__Info">
                <div className="Profile__Left">
                  <img src={ProfileImg} />
                </div>
                <div className="Profile__Right">
                  <h3>{user.firstName + " " + user.lastName}</h3>
                  {user.role === "user" ? <p>Simple utilisateur</p> : ""}
                  {user.role === "patient" ? <p>Patient</p> : ""}
                  {user.role === "admin" ? <p>Administrateur</p> : ""}
                  {user.role === "doctor" ? <p>Docteur</p> : ""}
                </div>
              </div>
            </div>
            <div className="Profile__CoverActions">
              <a className="Cover__Button" href={`/users/edit/${id}`}>
                Modifier mon compte
              </a>
            </div>
          </div>
          <div className="Profile__Nav">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#account">
                  Compte utilisateur
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Profile__Content">
          <fieldset id="account">
            <legend>Mon compte</legend>
            <div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <ShieldOutlinedIcon className="ProfileIcon" />
                  <span>Role</span>
                </div>
                <div className="col-3 Info">
                  {user.role === "user" ? <span>Simple utilisateur</span> : ""}
                  {user.role === "patient" ? <span>Patient</span> : ""}
                  {user.role === "admin" ? <span>Administrateur</span> : ""}
                  {user.role === "doctor" ? <span>Docteur</span> : ""}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <EmailOutlinedIcon className="ProfileIcon" />
                  <span>Adresse Email</span>
                </div>
                <div className="col-3 Info">{user.email}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <CallOutlinedIcon className="ProfileIcon" />
                  <span>Telephone</span>
                </div>
                <div className="col-3 Info">{user.telephone}</div>
              </div>
            </div>
          </fieldset>
          <fieldset id="profile">
            <legend>Mon Profile</legend>
            <div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <PersonOutlinedIcon className="ProfileIcon" />
                  <span>Nom</span>
                </div>
                <div className="col-3 Info">{user.firstName}</div>
              </div>
              <div className="row mb-3">
                <div className="col-3 LabelIcon">
                  <PersonOutlinedIcon className="ProfileIcon" />
                  <span>Prenoms</span>
                </div>
                <div className="col-3 Info">{user.lastName}</div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
}
