import React from "react";
import "./DetailLayout.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProfileImg from "../images/profile.png";

const DetailLayout = ({
  title,
  description,
  actions = [],
  tabs = [],
  children,
}) => {
  return (
    <div className="DetailLayout">
      <div className="DetailLayout__Header">
        <div className="DetailLayout__Wrapper">
          <div className="DetailLayout__HeaderTitle">
            <a href=".">
              <ArrowBackIcon className="Icon" />
            </a>
            <div className="DetailLayout__Info">
              <div className="DetailLayout__InfoLeft">
                <img src={ProfileImg} alt="" />
              </div>
              <div className="DetailLayout__InfoRight">
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            </div>
          </div>
          <div className="DetailLayout__HeaderActions">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" href="#/">
                  Infos Gen.
                </a>
              </li>
              {actions &&
                actions.map((action, index) => (
                  <li className="nav-item">
                    <a className="nav-link" key={index} href={action.url}>
                      {action.title}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="DetailLayout__Content">
        <div className="DetailLayout__Wrapper">{children}</div>
      </div>
    </div>
  );
};

export default DetailLayout;
