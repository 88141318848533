import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import { MyTextInput, MyCheckbox, MySelect } from "../../components/Inputs";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { alertService } from "../../services/AlertService";

import { FetchWrapper } from "../../http/apiRequests";
import {
  ADD_REQUEST,
  EDIT_REQUEST,
  GET_REQUEST,
  GET_DOCTOR_LIST,
} from "../../constants/apiEndpoints";

import * as Yup from "yup";

const RequestValidate = (props) => {
  const request = FetchWrapper();
  const id = props.id;
  console.log(props);
  const isAddMode = !id;

  const [doctors, setDoctors] = useState([]);

  useEffect(async () => {
    const response = await request.get(GET_DOCTOR_LIST);
    setDoctors(response);
  }, []);

  const initialValues = {
    status: "",
    doctor: "",
  };

  const validationSchema = Yup.object({
    status: Yup.string().required("Champs requis"),
    doctor: Yup.string().required("Champs requis"),
  });

  const handleSubmit = (values, { setStatus, setSubmitting, resetForm }) => {
    setStatus();
    updateRequest(id, values, setSubmitting);
  };

  const updateRequest = async (id, values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(EDIT_REQUEST + id, values);
      if (response) {
        alertService.success("Mise a jour effectuer", {
          keepAfterRouteChange: true,
        });
        if (props.callback) {
          props.callback(response);
        }
        props.onClose();
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal xl" onClick={props.onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">{props.title}</h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {function Render({ isSubmitting }) {
              return (
                <Form>
                  <div className="modal-body">
                    <p>Saisissez les informations ci-dessous</p>
                    <MySelect name="status">
                      <option value="">Choisir le statut</option>
                      <option value="accepted">VALIDER</option>
                      <option value="cancelled">ANNULER</option>
                    </MySelect>
                    <MySelect name="doctor">
                      <option value="">Choisir un doctor</option>
                      {doctors &&
                        doctors.map((doctor, index) => (
                          <option key={index} value={doctor.id}>
                            {doctor.firstName + " " + doctor.lastName}
                          </option>
                        ))}
                    </MySelect>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-lg btn-success"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Enregistrer
                    </button>
                    <button
                      onClick={props.onClose}
                      className="btn btn-lg btn-light"
                    >
                      Annuler
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default RequestValidate;
