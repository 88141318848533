import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";

export default function PrivateRoute({ redirectPath = "/login" }) {
  const token = window.localStorage.getItem("admin");
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div className="App">
      <div className="App__Nav">
        <Sidebar />
      </div>
      <div className="App__Main">
        <Outlet />
      </div>
    </div>
  );
}
