import React, { useState, useEffect } from "react";
import FormLayout from "../../layouts/FormLayout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import { alertService } from "../../services/AlertService";

import {
  ADD_APPOINTMENT,
  EDIT_APPOINTMENT,
  GET_APPOINTMENT,
  GET_DOCTOR_LIST,
  GET_PATIENT_LIST,
} from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../http/apiRequests";

import * as Yup from "yup";

const AppointmentForm = () => {
  const request = FetchWrapper();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = !id;

  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);

  useEffect(async () => {
    const _doctors = await request.get(GET_DOCTOR_LIST);
    setDoctors(_doctors);

    const _patients = await request.get(GET_PATIENT_LIST);
    setPatients(_patients);
  }, []);

  const initialValues = {
    motif: "",
    date: "",
    patient: "",
    doctor: "",
  };

  const validationSchema = Yup.object({
    motif: Yup.string().required("Renseigner le motif"),
    date: Yup.string().required("Renseigner le date"),
    patient: Yup.string().required("Selectionner un patient"),
    doctor: Yup.string().required("Selectionner un docteur"),
  });

  const handleSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus();
    if (isAddMode) {
      createAppointment(values, setSubmitting);
    } else {
      updateAppointment(id, values, setSubmitting);
    }
  };

  const createAppointment = async (values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(ADD_APPOINTMENT, values);
      if (response) {
        alertService.success("Nouveau rendez-vous creer", {
          keepAfterRouteChange: true,
        });
        const { from } = location.state || {
          from: { pathname: "." },
        };
        navigate(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  const updateAppointment = async (id, values, setSubmitting) => {
    alertService.clear();
    try {
      const response = await request.post(EDIT_APPOINTMENT + id, values);
      if (response) {
        alertService.success("Mise a jour effectuer", {
          keepAfterRouteChange: true,
        });
        const { from } = location.state || {
          from: { pathname: ".." },
        };
        navigate(from);
      }
    } catch (error) {
      setSubmitting(false);
      alertService.error(error.message);
    }
  };
  return (
    <FormLayout
      title={
        isAddMode
          ? "Ajouter un rendez-vous"
          : "Modifier les infos. du rendez-vous"
      }
      path={isAddMode ? "." : ".."}
      date="veuillez renseigner les informations ci-dessous"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {function Render({ isSubmitting, setFieldValue }) {
          const [appointment, setAppointment] = useState({});

          useEffect(async () => {
            if (!isAddMode) {
              const response = await request.get(GET_APPOINTMENT + id);
              setAppointment(response);
              console.log(response);
              if (response) {
                const fields = ["motif", "date", "doctor", "patient"];
                fields.forEach((field) => {
                  if (field === "date") {
                    let date = new Date(response[field]);

                    let day = date.getDate();
                    let month = date.getMonth() + 1;
                    let year = date.getFullYear();
                    setFieldValue(field, response.date, false);
                    console.log(field);
                  } else if (field === "patient" || field === "doctor") {
                    if (response[field]) {
                      setFieldValue(field, response[field].id, false);
                    }
                  } else {
                    setFieldValue(field, response[field], false);
                  }
                });
              }
            }
          }, []);

          return (
            <Form>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="doctor">
                  Selectionner le praticien
                </label>
                <div className="col-sm-5">
                  <Field
                    as="select"
                    className="form-control"
                    name="doctor"
                    id="doctor"
                  >
                    <option value="">Choisir dans la liste</option>
                    {doctors &&
                      doctors.map((doctor, index) => (
                        <option key={index} value={doctor.id}>
                          {doctor.firstName + " " + doctor.lastName}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage name="company" />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="motif">
                  Motif du RDV
                </label>
                <div className="col-sm-5">
                  <Field className="" as="select" name="motif">
                    <option value="">Selection du motif</option>
                    <option value="Consultation">Consultation</option>
                    <option value="Contre Expertise medicale">
                      Contre Expertise medicale
                    </option>
                    <option value="Sejour Medicale au Maroc">
                      Sejour Medicale au maroc
                    </option>
                  </Field>
                  <ErrorMessage name="motif" />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="date">
                  Date et heure
                </label>
                <div className="col-sm-5">
                  <Field className="form-control" name="date" type="date" />
                  <ErrorMessage className="invalid-feedback" name="date" />
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="patient">
                  Selection du patient
                </label>
                <div className="col-sm-5">
                  <Field
                    as="select"
                    className="form-control"
                    name="patient"
                    id="patient"
                  >
                    <option value="">Choisir dans la liste</option>
                    {patients &&
                      patients.map((patient, index) => (
                        <option key={index} value={patient.id}>
                          {patient.firstName + " " + patient.lastName}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage name="company" />
                </div>
              </div>

              <div>
                <button
                  className="btn btn-primary mr-3"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Enregistrer
                </button>
                <Link to={isAddMode ? "." : ".."} className="btn btn-light">
                  Annuler
                </Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default AppointmentForm;
